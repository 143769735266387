#bbs {
	background: #fff;
	.article {
		border-bottom: 1px dotted #000;
		padding: 10px 20px;
		.art-date {
			font-size: 13px;
			margin-bottom: 5px;
		}
		.art-sub,
		.art-comment {
			font-size: 15px;
		}
	}
}