.c-inner {
	max-width: calc(100% - 32px);
	width: 1160px;
	margin: 0 auto;
}
.c-titA {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	line-height: 1.5;
	img {
		margin-right: 10px;
	}
	&__text {
		font-size: 32px;
		color: #fff;
		@media screen and (max-width: 767px) {
			font-size: 20px;
		}
	}
}
.c-titB {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
	img {
		margin-right: 20px;
	}
	&__text {
		font-size: 30px;
		@media screen and (max-width: 767px) {
			font-size: 20px;
		}
		small {
			font-size: 24px;
			color: #df5411;
			@media screen and (max-width: 767px) {
				font-size: 16px;
			}
		}
	}
}