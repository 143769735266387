#top {
	.sec01 {
		text-align: center;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 50%);
		max-width: 1339px;
		margin: 0 auto;
		.slick-slide {
			outline: none;
		}
		.slick-dots {
			bottom: 10px;
			@media screen and (max-width: 767px) {
				bottom: 0;
			}
			li {
				button {
					padding: 0;
					width: auto;
					height: auto;
					margin: 0;
					&:before {
						color: #fff;
						opacity: 1;
						font-size: 12px;
					}
				}
				&.slick-active,
				&:hover {
					button {
						&:before {
							color: #8fc31f;
						}
					}
				}
			}
		}
		.slick-next,
		.slick-prev {
			width: 26px;
			height: 26px;
			border-top: 1px solid #fff;
			border-right: 1px solid #fff;
			z-index: 10;
			&:before {
				content: none;
			}
		}
		.slick-prev {
			transform: translateY(-50%) rotate(-135deg);
			left: 40px;
			@media screen and (max-width: 767px) {
				left: 10px;
			}
		}
		.slick-next {
			transform: translateY(-50%) rotate(45deg);
			right: 40px;
			@media screen and (max-width: 767px) {
				right: 10px;
			}
		}
	}
	.sec03 {
		&__inner {
			display: flex;
			@media screen and (max-width: 767px) {
				display: block;
			}
			.image {
				margin-right: 50px;
				@media screen and (max-width: 767px) {
					text-align: center;
					margin-right: 0;
					margin-bottom: 20px;
				}
			}
			.text {
				font-size: 16px;
				max-width: 580px;
				@media screen and (max-width: 767px) {
					margin-bottom: 40px;
				}
			}
		}
	}
	.sec02 {
		margin-bottom: -5px;
		position: relative;
		background-color: #8a7b6d;
		padding: 65px 0;
		@media screen and (max-width: 767px) {
			padding: 40px 0;
		}
		.c-titA {
			justify-content: center;
		}
		&__flex {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				display: block;
			}
		}
		&__left {
			width: 568px;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
			.topics {
				background: #fff;
				padding: 60px 40px 50px;
				border-radius: 10px;
				box-shadow: 8px 8px #b1a8a3;
				position: relative;
				@media screen and (max-width: 767px) {
					padding: 60px 20px 30px;
				}
				&:before {
					content: "";
					position: absolute;
					top: -11px;
					left: 0;
					right: 0;
					height: 50px;
					background: url(../img/top/topics_bg.png) top center no-repeat;
				}
				iframe {
					width: 100%;
					height: 250px;
				}
			}
		}
		&__right {
			width: 491px;
			@media screen and (max-width: 767px) {
				width: 100%;
				margin-top: 40px;
			}
			.text01 {
				text-align: center;
				font-size: 16px;
				color: #fff;
				margin: 10px 0 20px;
			}
		}
	}
	.sec04 {
		padding-top: 60px;
		.c-inner {
			@media screen and (max-width: 767px) {
				max-width: 100%;
			}
		}
		.c-titA {
			justify-content: center;
			&__text {
				color: black;
			}
		}
		&__text {
			font-size: 30px;
			font-weight: bold;
			position: relative;
			text-align: center;
			margin-bottom: 30px;
			@media screen and (max-width: 767px) {
				font-size: 20px;
			}
			small {
				font-size: 20px;
				font-weight: normal;
				@media screen and (max-width: 767px) {
					font-size: 15px;
					display: inline-block;
				}
			}
			img {
				position: absolute;
				bottom: 0;
			}
		}
		&-map {
			&__frame {
				width: 100%;
				height: 400px;
				iframe {
					width: 100%;
					height: 100%;
				}
			}
		}
		&-text {
			background-color: #8a7b6d;
			padding: 35px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: 767px) {
				display: block;
				padding: 20px;
			}
			&__text01 {
				color: white;
				@media screen and (max-width: 767px) {
					text-align: center;
				}
				.text01 {
					font-size: 16px;
					margin-left: 10px;
					@media screen and (max-width: 767px) {
					}
				}
				.text02 {
					font-size: 31px;
					font-weight: bold;
					@media screen and (max-width: 767px) {
						font-size: 25px;
					}
				}
				.text03 {
					font-size: 42px;
					font-weight: bold;
					margin: 0 5px;
					@media screen and (max-width: 767px) {
						font-size: 30px;
					}
				}
			}
			&__text02 {
				border: 3px solid white;
				color: white;
				border-radius: 7px;
				font-size: 15px;
				padding: 20px;
				margin: 0 25px;
				@media screen and (max-width: 767px) {
					margin: 20px 0;
				}
			}
			&__map {
				@media screen and (max-width: 767px) {
					text-align: center;
				}
			}
			&__map {
				text-align: center;
				padding: 50px 20px;
				background-color: #8a7b6d;
				@media screen and (max-width: 767px) {
					padding: 10px;
				}
			}
		}
	}
	.sec05 {
		padding-top: 80px;
		max-width: 935px;
		margin: 0 auto;
		.c-titA {
			justify-content: center;
			&__text {
				color: black;
			}
		}
	}
	.sec05-list {
		border: 1px solid white;
		padding: 15px;
		display: flex;
		justify-content: center;
		@media screen and (max-width: 767px) {
			padding: 10px;
		}
		&__wrap {
			background: #8fc31f;
			padding: 30px 70px;
			border-radius: 10px;
			@media screen and (max-width: 767px) {
				padding: 10px;
			}
		}
		p {
			font-size: 14px;
			color: white;
			padding-top: 20px;
			padding-bottom: 20px;
			line-height: 1.8;
			@media screen and (max-width: 767px) {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
		.left {
			text-align: right;
			margin-right: 35px;
			padding-right: 35px;
			border-right: 1px solid white;
			@media screen and (max-width: 767px) {
				margin-right: 10px;
				padding-right: 10px;
			}
		}
		.right {
			text-align: left;
		}
	}
}
