#about {
	.sec01 {
		text-align: center;
		background: linear-gradient(180deg,#fff 0,#fff 50%,hsla(0,0%,100%,0) 0);
	}
	.sec02 {
		margin-bottom: 80px;
		@media screen and (max-width: 767px) {
		}
		.c-titA {
			justify-content: center;
			margin-bottom: 60px;
			@media screen and (max-width: 767px) {
				margin-bottom: 30px;
			}
			&__text {
				color: black;
			}
		}
		.c-inner {
			width: 1040px;
			position: relative;
			padding-bottom: 120px;
			@media screen and (max-width: 767px) {
				padding-bottom: 40px;
			}
		}
		.cat {
			position: absolute;
			bottom: -80px;
			right: -30px;
			@media screen and (max-width: 767px) {
				right: 0;
				//bottom: -60px;
				width: 130px;
			}
		}
	}
	.sec02-flex {
		display: flex;
		@media screen and (max-width: 767px) {
			display: block;
		}
		+ .sec02-flex {
			margin-top: 75px;
			@media screen and (max-width: 767px) {
				margin-top: 40px;
			}
		}
		&__image {
			width: 310px;
			text-align: center;
			margin-right: 70px;
			@media screen and (max-width: 767px) {
				width: 100%;
				text-align: center;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
		&__textarea {
			width: calc(100% - 380px);
			@media screen and (max-width: 767px) {
				width: 100%;
			}
			.title01 {
				color: #8fc31f;
				line-height: 1.5;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				@media screen and (max-width: 767px) {
					flex-wrap: wrap;
				}
				.text01 {
					font-size: 18px;
					margin-right: 30px;
					@media screen and (max-width: 767px) {
						font-size: 18px;
					}
				}
				.text02 {
					font-size: 30px;
					margin-right: 10px;
					@media screen and (max-width: 767px) {
						font-size: 20px;
					}
				}
				.text03 {
					font-size: 18px;
					@media screen and (max-width: 767px) {
						font-size: 16px;
					}
				}
			}
			.text {
				line-height: 2;
				+ .text {
					margin-top: 20px;
				}
			}
			.table_wrap {
				padding: 20px 0;
				border-top: 1px solid #898989;
				border-bottom: 1px solid #898989;
			}
			.table {
				display: flex;
				align-items: flex-start;
				@media screen and (max-width: 767px) {
					display: block;
				}
				+ .table {
					margin-top: 20px;
				}
				.title01 {
					width: 150px;
					margin-bottom: 0;
					transform: translateY(-5px);
					@media screen and (max-width: 767px) {
						margin-bottom: 10px;
					}
					.text01 {
						font-size: 24px;
					}
				}
				table {
					width: calc(100% - 150px);
					@media screen and (max-width: 767px) {
						width: 100%;
					}
				}
			}
			table {
				text-align: left;
				font-size: 15px;
				tr {
					th {
						font-weight: normal;
						width: 140px;
						@media screen and (max-width: 767px) {
							width: 100px;
						}
					}
					td {
						padding: 2px 0;
						&.born {
							padding-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.sec03 {
		background: #8a7b6d;
		.c-inner {
			padding: 70px 0 110px;
			width: 935px;
			@media screen and (max-width: 767px) {
				padding: 40px 0 20px;
			}
		}
		.c-titA {
			justify-content: center;
			margin-bottom: 70px;
			@media screen and (max-width: 767px) {
				margin-bottom: 30px;
			}
		}
		&__text {
			display: flex;
			align-items: center;
			margin-bottom: 60px;
			@media screen and (max-width: 767px) {
				display: block;
				margin-bottom: 30px;
			}
			.title {
				@media screen and (max-width: 767px) {
					text-align: center;
				}
			}
			.text {
				font-size: 16px;
				color: white;
				max-width: 595px;
				margin-left: 40px;
				@media screen and (max-width: 767px) {
					margin-left: 0;
					margin-top: 20px;
				}
			}
		}
		dl {
			display: flex;
			position: relative;
			background: #fff;
			border-radius: 10px;
			padding: 20px 0;
			@media screen and (max-width: 767px) {
				display: block;
			}
			+ dl {
			}
			dt {
				justify-content: space-between;
				width: 250px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				font-size: 30px;
				font-weight: bold;
				border-right: 1px dashed black;
				@media screen and (max-width: 767px) {
					width: 100%;
					font-size: 20px;
				}
			}
			dd {
				width: calc(100% - 250px);
				display: flex;
				align-items: center;
				padding: 3px 60px;
				@media screen and (max-width: 767px) {
					width: 100%;
					flex-wrap: wrap;
					height: auto;
					padding: 20px 10px;
					justify-content: center;
				}
				span {
					display: flex;
					flex-direction: column;
					align-items: center;
					align-self: flex-end;
					@media screen and (max-width: 767px) {
						width: 33.333%;
					}
					+ span {
						margin-left: 30px;
						@media screen and (max-width: 767px) {
							margin-left: 0;
							margin-top: 10px;
						}
					}
					p {
						margin-top: 10px;
						font-size: 14px;
					}
				}
			}
		}
		&__text02 {
			margin: 20px 0 60px;
			font-size: 18px;
			text-align: center;
			color: white;
			@media screen and (max-width: 767px) {
				margin-bottom: 30px;
			}
		}
		.night {
			border-radius: 10px;
			padding: 30px 90px;
			background-color: #fff;
			@media screen and (max-width: 767px) {
				padding: 20px;
			}
			.c-titA {
				justify-content: center;
				margin-bottom: 20px;
				&__text {
					color: black;
				}
			}
			p {
				text-align: center;
				.big {
					font-size: 18px;
				}
				.cap {
					display: block;
					margin-top: 10px;
					font-size: 14px;
					color: #8fc31f;
				}
				+ p {
				}
			}
			.tel {
				margin-top: 20px;
				a {
					display: block;
					background: #8fc31f;
					border-radius: 10px;
					text-align: center;
					padding: 12px;
				}
			}
		}
	}
	.sec04 {
		padding-top: 80px;
		@media screen and (max-width: 767px) {
			padding-top: 40px;
		}
		.c-inner {
			width: 1000px;
		}
		.c-titA {
			justify-content: center;
			&__text {
				color: black;
			}
		}
		.text {
			text-align: center;
			font-size: 20px;
			color: #8fc31f;
			@media screen and (max-width: 767px) {
				font-size: 16px;
			}
		}
	}
	.sec04-slider {
		position: relative;
		.left {
			position: absolute;
			top: 120px;
			left: 0;
		}
		.right {
			position: absolute;
			top: 180px;
			right: -40px;
		}
		#js-slider {
			margin-bottom: 35px;
			@media screen and (max-width: 767px) {
				margin-bottom: 10px;
			}
			.slider-item {
				position: relative;
				.image {
					width: 386px;
					max-width: 100%;
					margin: 0 auto;
					position: relative;
					span {
						width: 15px;
						height: 15px;
						border-top: 2px solid black;
						border-left: 2px solid black;
						position: absolute;
						&:nth-of-type(1) {
							top: 0;
							left: 0;
						}
						&:nth-of-type(2) {
							top: 0;
							right: 0;
							transform: rotate(90deg);
						}
						&:nth-of-type(3) {
							bottom: 0;
							right: 0;
							transform: rotate(180deg);
						}
						&:nth-of-type(4) {
							bottom: 0;
							left: 0;
							transform: rotate(270deg);
						}
					}
				}
				p {
					position: absolute;
					bottom: 0;
					left: calc(50% + 220px);
					font-size: 21px;
					@media screen and (max-width: 767px) {
						position: static;
						font-size: 16px;
						margin-top: 5px;
					}
				}
			}
		}
		.slider-nav {
			margin-bottom: 60px;
			margin-left: -3px;
			margin-right: -3px;
			.slick-slide {
				cursor: pointer;
				line-height: 1;
				padding: 0 3px;
				transition: 0.3s ease;
				outline: none;
				max-height: 120px;
				img {
					border: 1px solid transparent;
				}
				&.slick-current img {
					border: 1px solid #8fc31f;
				}
				@media screen and (max-width: 767px) {
					max-height: calc(100vw / 10);
				}
				&:hover {
					opacity: 0.7;
				}
			}
			.slick-arrow {
				width: 17px;
				position: absolute;
				top: calc(50% - 16px);
				z-index: 10;
				cursor: pointer;
				@media screen and (max-width: 767px) {
					display: none !important;
				}
			}
			.prev {
				left: -60px;
			}
			.next {
				right: -60px;
				transform: rotate(180deg);
			}
		}
	}
}
