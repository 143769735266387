* {
	backface-visibility: hidden;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;

	&:before,
	&:after {
		backface-visibility: hidden;
		box-sizing: border-box;
	}
}

html {
	font-size: 10px;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	background-color: #fff;
	font-family: Yu Gothic Medium, 游ゴシック Medium, YuGothic, 游ゴシック体, ヒラギノ角ゴ Pro W3, メイリオ, sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	color: #333333;
	min-width: 1200px;
	background: url(../img/common/bigbg.png);
	@media screen and (max-width: 767px) {
		//font-size: 12px;
		min-width: auto;
	}
	@media all and (-ms-high-contrast: none), print {
		font-family: sans-serif;
	}
}

a,
button {
	text-decoration: none;
	color: #333333;
	outline: none;
	transition: 0.3s ease;
	&:hover {
		opacity: 0.7;
	}
}

// image

img,
svg,
video {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

// table

table {
	border-collapse: collapse;
	max-width: 100%;
	width: 100%;

	th,
	td {
		vertical-align: top;
	}
}

// IE11のクリアボタンを非表示

input::-ms-clear {
	display: none;
}

ul,
ol {
	list-style-position: inside;
}

input,
button,
textarea,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	border: none;
	border-radius: 0;
	margin: 0;
	outline: none;
	padding: 0;
}

select::-ms-expand {
	display: none;
}

button {
	&:focus {
		outline: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
}

@media screen and (min-width: 768px) {
	.sp {
		display: none !important;
	}
}
@media screen and (max-width: 767px) {
	.pc {
		display: none !important;
	}
}

.header {
	padding: 25px 0;
	background: #fff;
	@media screen and (max-width: 767px) {
		padding: 10px;
	}
	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: 767px) {
			display: block;
		}
	}
	&__logo {
		margin-right: 100px;
		@media screen and (max-width: 767px) {
			margin-right: 0;
		}
	}
	&__right {
	}
}
.header-cv {
	display: flex;
	align-items: center;
	margin-bottom: 35px;
	@media screen and (max-width: 767px) {
		display: block;
		margin-bottom: 10px;
	}
	&__tel {
		margin-right: 30px;
		text-align: center;
		@media screen and (max-width: 767px) {
			margin-right: 0;
			margin-top: 10px;
		}
		.num {
		}
		.text {
			font-size: 15px;
			margin-top: 15px;
		}
	}
	&__res {
		@media screen and (max-width: 767px) {
			text-align: center;
			margin-top: 10px;
		}
		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #8fc31f;
			border-radius: 10px;
			height: 40px;
			width: 280px;
			@media screen and (max-width: 767px) {
				margin: 0 auto;
			}
			img {
			}
			&__text {
				font-size: 18px;
				color: white;
				margin-left: 15px;
			}
		}
		.text {
			font-size: 15px;
		}
	}
}
.header-nav {
	nav {
		display: flex;
		align-items: center;
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
		}
		a {
			display: flex;
			align-items: center;
			font-size: 16px;
			margin-right: 30px;
			@media screen and (max-width: 767px) {
				width: 50%;
				margin-right: 0;
				padding: 5px;
			}
			&.insta {
				margin-left: auto;
				justify-content: center;
			}
			&.fb {
				justify-content: center;
			}
			.text {
				margin-left: 15px;
			}
		}
	}
}

.sub-nav {
	margin: 20px 0 70px;
	@media screen and (max-width: 767px) {
		margin-bottom: 40px;
		padding: 0 10px;
	}
	&__inner {
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: 767px) {
			display: block;
		}
		.text {
			margin-left: 35px;
			@media screen and (max-width: 767px) {
				margin-top: 10px;
				margin-bottom: 10px;
				text-align: center;
				margin-left: 0;
			}
		}
		.tel {
			margin-left: 35px;
			@media screen and (max-width: 767px) {
				text-align: center;
				margin-left: 0;
			}
		}
		nav {
			display: flex;
			@media screen and (max-width: 767px) {
				justify-content: space-between;
			}
			a {
				+ a {
					margin-left: 35px;
					@media screen and (max-width: 767px) {
						margin-left: 10px;
					}
				}
			}
		}
	}
}
.footer {
	margin-top: 100px;
	@media screen and (max-width: 767px) {
		margin-top: 50px;
	}
}
.footer-nav {
	padding: 0 0 300px;
	background: url(../img/common/ftr_bg.png) center bottom no-repeat;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	@media screen and (max-width: 767px) {
		padding-bottom: 100px;
		background-size: 100% auto;
	}
	&__inner {
		max-width: 980px;
		margin: 0 auto;
		background-size: auto 190px;
		@media screen and (max-width: 767px) {
			padding: 0 10px;
			background: none;
		}
	}
	&__primary {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}
		a {
			@media screen and (max-width: 767px) {
			}
			+ a {
				margin-left: 40px;
				@media screen and (max-width: 767px) {
					margin-left: 10px;
				}
			}
		}
	}
	&__secondary {
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (max-width: 767px) {
			flex-wrap: wrap;
			justify-content: space-between;
		}
		a {
			display: flex;
			align-items: center;
			font-size: 16px;
			margin-right: 30px;
			@media screen and (max-width: 767px) {
				width: 50%;
				margin-right: 0;
				padding: 5px;
			}
			.text {
				margin-left: 15px;
			}
		}
	}
}
.footer-main {
	background: #8a7b6d;
	margin-top: -5px;
	padding: 70px 0 10px;
	text-align: center;
	&__logo {
		margin-bottom: 30px;
	}
	&__tel {
	}
	&__addr {
		margin-top: 10px;
		color: white;
		font-size: 16px;
	}
	&__copy {
		padding-top: 60px;
		font-size: 12px;
		color: #fff;
	}
}
